import { INPUT_UPDATE, INPUT_INITIALIZE } from '../actions/index';
import {initialState} from '../components/default/default';

export default function (state = initialState, action) {

    switch (action.type) {
        case INPUT_UPDATE:
          return { ...state, [action.payload.prop]: action.payload.value };
        case INPUT_INITIALIZE:
            return { initialState };
        default:
          return state;
    }
}
