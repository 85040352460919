// Data structure below used for running CoBi (Backend)
export const initialState = {

    proj_name: 'Micelles',

    // The name of the variables to be inserted into the Cobi (.sim) script file and their default values
    params: {'perm_m': 1e-7,
            'radius_m': 100,
            'Kp': 1000.0,
            'Kpi': 10.0,
            'total_vol': 10.0,
            'reser_vol': 5.0,
            'total_nsteps': 25
        },

    // Factors to scale 'params' with before sending them to Cobi 
    params_scale: ['1',
                    '1',
                    '1',
                    '1',
                    '1',
                    '1',
                    '360'
                ],

    // Intervals that limit the params values
    params_range: [ [Number.EPSILON, Infinity],
                    [1, Infinity],
                    [Number.EPSILON, Infinity],
                    [1, Infinity],
                    [1e-3, Infinity],
                    [5e-4, 'total_vol'],
                    [1/360.0, Infinity]
                ]
  };

// Array below used for the describing the parameters (Frontend) 
export const params_description = [
            'Micelle Permeability (m/s)',
            'Micelle Radius (nm)',
            'Partition Coefficient (Kp)',
            'Interior Partition Coefficient (Kpi)',
            'Total Volume (L)',
            'Reservoir Volume (L)',
            'Simulation Time (Hours)'
            ];

// Data structure below used for generating 2D plots (Frontend)
export const plot_data = {

            // time = index 0 (1st column)

            'xlabel': ['Time (hours)', 'Time (hours)'],
            'ylabel': ['% Mass Left', 'Concentration (mg/L)'],

            'title': ['Drug Left', 'Drug Concentration in Reservoir'],

            'xscale': [1.0/(60.0*60.0), 1.0/(60.0*60.0)],
            'yscale': [100.0, 1e3],

            'xlog': ['linear', 'linear'],
            'ylog': ['linear', 'linear'],
            'data': [ [0, 2], [0, 1] ]
            };