import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchCobiList } from '../actions/index';
import LineChart from './charts/lineChart';
import {initialState, plot_data} from './default/default.js';

class CobiGraph extends Component {

  constructor(props) {
    super(props);

    this.selectedOption = 'Linear'
    const margin1 = 30;
    const numberOfIncrements = 10;
    const boxWidth = (this.props.timeListWidth - (2 * margin1)) / numberOfIncrements;
    const boxHeight = 20;

    this.state = {
      screen: {
        width: this.props.timeListWidth,
        height: this.props.timeListHeight,
        ratio: 1,
        boxwidth: boxWidth,
        boxheight: boxHeight,
        margin: margin1,
        axisStartY: 150,
        strokeW: 2,
      },
      default_params: initialState.params,
      num_plots: plot_data.data.length
  };
}

 iterationCopy(src) {
  let target = {};
  for (let prop in src) {
    if (src.hasOwnProperty(prop)) {
      target[prop] = src[prop];
    }
  }
  return target;
}

  createTable = () => {

    let table = [];
    let children = [];

    let height = 100 / Math.ceil(this.state.num_plots / 2);
    let custom_styles = {
        buttonView: {
          //backgroundColor: '#008080',
          width: '100%',
          height: 40,
        },

        legendContainer: {
          width: '95%',
          height: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },

        legendSubContainer: {
          width: '33%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        },

        legendText: {
            fontSize: 14,
            color: 'white',
          },

        graphViewMain: {
            width: '90%',
            height: '250%',
            flexDirection: 'column',
          },

        graphViewRow: {
            width: '100%',
            height: '34.25%',
            flexDirection: 'row',
          },

        radioButtonView: {
          width: '20%',
          height: '100%',
          flexDirection: 'column',
        },

        graphViewLeft: {
              float: 'left',
              width: '50%',
              height: '50%',
              flexDirection: 'row',
            },

        graphViewRight: {
              float: 'right',
              width: '50%',
              height: `${height}%`,
              },

        imageViewRight: {
              height: '125%',
              backgroundSize: 'auto',
              },
        disclaimer: {
              color: 'white',
        },

        text_update: {
          color:'red',
          backgroundColor:'black',
          fontWeight:'bold'
        }

      }

    // Outer loop to create parent
    for (var i = 0; i < this.state.num_plots; i++) {

      if( (i%2) === 0) {
        children.push(<div key={`${i}`} style={custom_styles.graphViewRight}><LineChart index={`${i+1}`} color="blue"/></div>);
      }
      else {
        children.push(<div key={`${i}`} style={custom_styles.graphViewRight}><LineChart index={`${i+1}`} color="red"/></div>);
      }
    }

    table.push(<div key={2973483647346} style={custom_styles.graphViewRow}> {children} </div>);

    return table;
  }


  componentWillMount() {
    if (this.props.fetchCobiList) {
      this.props.fetchCobiList( this.props.params, this.props.params_names, 
        this.props.time);
    }
  }

  updateText(msg) {
    document.getElementById('text_state').innerHTML = `<div style={styles.text_update}}>${msg}</div>`
  }

  render() {

    if ((this.props.cobiList == null) || (this.props.cobiList.length === 0) ) {
      return (null);
    }

    document.getElementById('text_state').innerHTML = "<div>Solution computed</div>";

    return (<div style={styles.graphViewMain}> {this.createTable()} </div>);
  }

}

// makes the container
function mapStateToProps(state) {
  const { params, time } = state.cobiInput;
  return {
    params,
    time,
    cobiList: state.cobiList
  };
}

function mapDispatchToProps(dispatch) {
  //console.log("in dispatchtoprops"+dispatch);
  return bindActionCreators({ fetchCobiList }, dispatch);
}

//export default TimeList;
export default connect(mapStateToProps, mapDispatchToProps)(CobiGraph);

let styles = {
  buttonView: {
    //backgroundColor: '#008080',
    width: '100%',
    height: 40,
  },

  legendContainer: {
    width: '95%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  legendSubContainer: {
    width: '33%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  legendText: {
      fontSize: 14,
      color: 'white',
    },

  graphViewMain: {
      width: '90%',
      height: '250%',
      flexDirection: 'column',
    },

  graphViewRow: {
      width: '100%',
      height: '34.25%',
      flexDirection: 'row',
    },

  radioButtonView: {
    width: '20%',
    height: '100%',
    flexDirection: 'column',
  },

  graphViewLeft: {
        float: 'left',
        width: '50%',
        height: '50%',
        flexDirection: 'row',
      },

  graphViewRight: {
        float: 'right',
        width: '50%',
        height: '50%',
        },

  imageViewRight: {
        height: '125%',
        backgroundSize: 'auto',
        },
  disclaimer: {
        color: 'white',
  },

  text_update: {
    color:'red',
    backgroundColor:'black',
    fontWeight:'bold'
  }

}
