import React, { Component } from 'react';
import OdeGraphMain from "./odeGraphMain";
import { NavLink } from 'react-router-dom';


class App extends Component {

 // This function will create Tabs in the component.

  renderTabs() {
    //if ((this.props.authenticated) && (this.props.loggedInUser)) {
    return(
        <div >
          <ul style={styles.MenuLIDiv}>
            <li style={styles.MenuLI}>
              <NavLink style={styles.MenuLIA} activeStyle={{ color: 'blue' }} to='/Home'>Home</NavLink>
            </li>
            <li style={styles.MenuLI}>
              <NavLink style={styles.MenuLIA} activeStyle={{ color: 'blue' }} to='/Documentation'>Documentation</NavLink>
            </li>
          </ul>
        </div>
     );
  }

  // This render statement constructs the first level of the webpage

  render(){
    return(
      <div style={styles.InputContainer}>
      {/* The Header or top of the webpage*/}
        <div style={styles.Header}>
          <p>Micelle Drug Release in Oil/Water</p>
        </div>

        {/* The remainder of the webpage constructed in odeGraphMain.js*/}
        <div style={styles.Mainbody}>
          <OdeGraphMain/>
        </div>
      </div>
  );
}

}

export default (App)

// Tabs in render

// <div style={styles.Tabs}>
//   <div style={styles.Menu}>
//     <nav>
//         {this.renderTabs()}
//     </nav>
//   </div>
// </div>

// Styles used to define the behavior of the cmomponents in the DIV refrenced
const styles = {
  InputContainer:{
    //width: '100%',
    height: 900,
    marginLeft:0,
  	marginRight:0,
  	marginTop: 0,
    //backgroundColor: 'transparent',
    backgroundColor: '#01759b',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

},

Header:{
  height: '6%',
  color:'white',
  width: '100%',
  fontSize: 40,
  //fontFamily: ,,
  textAlign: 'center',
  fontFamily: 'Tahoma',
  fontStyle: 'italic',
  backgroundColor: '#132F57',
},

Tabs:{
  height: '14%',
  color:'gray',
  width: '100%',
  fontSize: 30,
  fontFamily: 'Brush Script Std',
  fontStyle: 'italic',
  textAlign: 'center',
  backgroundColor: '#DBDDE8',
},

TabNameText: {
  textDecoration: 'none',
  //textAlign: 'center',
  fontSize: 18,
  fontFamily: 'Brush Script Std',
  fontStyle: 'italic',
  width: 150,

  marginLeft: 30
},

Mainbody:{
  height: '94%',
  //height: '100%',
  //width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  textAlign: 'center',
  //display: 'flex',
  baclgroundColor: "transparent",
//  overflow: auto,
},

Menu: {
	height: 60,
	backgroundColor:'#DBDDE8',

},

MenuLIDiv:{
  flex:1,
  marginLeft:0,
  marginRight:0,
  marginTop: 0,
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
 },

 MenuLI: {
 //textDecoration: 'none',
 listStyleType: 'none',
 float:'left',
 display: 'block',
 //width: 250,
 //height: 60,
  textAlign: 'center',
 //lineHeight: 55,
},

MenuLIA: {
 textDecoration: 'none',
  color:'black',
  textAlign: 'center',
  fontSize: 24,
  fontFamily: 'Brush Script Std',
  fontStyle: 'italic',
  fontWeight: 'bold',
},

MenuUL:{
	margin:0,
	padding:0,
},

MenuLIHover: {
	backgroundColor:'green',
}
}
