import React from 'react';
import { connect } from 'react-redux';
import { VictoryLine, VictoryChart, VictoryLabel, VictoryAxis } from 'victory';
import {plot_data} from '../default/default.js';


class LineChart extends React.Component {

  constructor(props) {
    super(props);
      this.getData = this.getData.bind(this);
      this.getTitle = this.getTitle.bind(this);
      this.getLabelY = this.getLabelY.bind(this);
      this.getLabelX = this.getLabelX.bind(this);

      this.getXLog = this.getXLog.bind(this);
      this.getYLog = this.getYLog.bind(this);

      this.selectedOption = 'Linear';
      this.handleOptionChangeLin=this.handleOptionChangeLin.bind(this);
      this.handleOptionChangeLog=this.handleOptionChangeLog.bind(this);
  }

  handleOptionChangeLin(changeEvent){
    this.selectedOption = 'Linear';
    console.log('check1');
  }
  handleOptionChangeLog(changeEvent){
    this.selectedOption = 'Logarithmic';
  }

  // This function interprets the returned CoBi outputs and sorts them into
  // each graph.

 getData(){
   let data = [];
   let dataRecord = {};

   if ((this.props === null) || (this.props.cobiList === null)) return null;

    for (let i = 0; i < this.props.cobiList.data.length; i++) {

       dataRecord = this.props.cobiList.data[i];
       var dataType = plot_data.data[this.props.index - 1];
       
       const xValue = dataRecord[dataType[0]] * plot_data.xscale[this.props.index - 1];
       const yValue = dataRecord[dataType[1]] * plot_data.yscale[this.props.index - 1];

       data.push({x: xValue, y: yValue});
    }

   return data;
 }

 getTitle(){
  return plot_data.title[this.props.index - 1];
 }

 getLabelY(){
   return plot_data.ylabel[this.props.index - 1];
 }

 getLabelX(){
   return plot_data.xlabel[this.props.index - 1];
 }

 getXLog(){
    return plot_data.xlog[this.props.index - 1];
 }

 getYLog(){
    return plot_data.ylog[this.props.index - 1];
 }

  render() {
    let data =this.getData();
    const color = this.props.color;
    let theTitle = this.getTitle();
    let labelY = this.getLabelY();
    let labelX = this.getLabelX();

    this.xlog = this.getXLog();
    this.ylog = this.getYLog();

    return (

      <VictoryChart
        style={styles.victoryChartView}
        color={"white"}
        padding={{left:80, right: 20, top: 50, bottom: 50}}
        scale = {{x: this.xlog, y: this.ylog}}
      >

  {/* This render statement determines the Y axis appearance */}

      <VictoryAxis dependentAxis
          style={tickStyle}
      />

      {/* This render statement determines the x axis appearance */}

      <VictoryAxis crossAxis
      style={tickStyle}
      />

      {/* This render statement defines the Title of the graphs */}

      <VictoryLabel
          style={{ fontSize: 20, fill: "white"}}
          fontSize={30}
          x={210} y={10}
          text={theTitle}
          textAnchor="middle"
        />

        {/* This render statement defines the Y axis label of the graphs */}

      <VictoryLabel
            style={{ fontSize: 15, fill: "white"}}
            x={15} y={225}
            text={labelY}
            angle={270}
          />

          {/* This render statement defines the X axis label of the graphs */}

      <VictoryLabel
                style={{ fontSize: 15, fill: "white"}}
                x={250} y={290}
                text={labelX}
              />
        <VictoryLine
          style= {{
              data: { stroke: color, strokeWidth: 2 },
              marginLeft:10,
            	marginRight:10,
            	marginTop: 20,
              strokeWidth: 1,
              parent: { border: "1px solid #ccc"},
              width: 100,
              height: '100%',
            }}
          padding= {{ top: 0, bottom: 0, left:10, right: 50 }}
          interpolation="natural"
          data={ data }
        />
       </VictoryChart>
    );
  }
}
//export default AreaChart;
export default connect(mapStateToProps, null)(LineChart);

// makes the container
function mapStateToProps(state) {

  return {
    cobiList: state.cobiList
  };
}

// The styles array further specifies render of the corresponding render

const styles = {
  victoryChartView: {
      width: '60%',
      //height: '100%',
      marginLeft:0,
    	marginRight:0,
    	marginTop: 0,
      data: { stroke: "#c43a31", strokeWidth: 1 },
      parent: { border: "1px solid #015f82"}
    },

    victoryLineChartView: {
        width: '60%',
        //height: '90%',
        marginLeft:0,
      	marginRight:0,
      	marginTop: 0,
        strokeWidth: 5,
        padding:{ top: 0, bottom: 0, left: 20, right: 0 },
        parent: { border: "1px solid #ccc"}
      },

      radioButtonView: {
        width: '30%',
        height: '10%',
      },

      graphView: {
        width: '100%',
        height: '100%',
      },
}

const tickStyle = {
      ticks: {
        size: 2,
        stroke: 'black',
        strokeOpacity: 0.1
      },
      grid: {
        stroke: 'rgba(0, 0, 0, 0.1)',
        strokeWidth: 1,
        strokeDasharray: '6, 6',
      },
      tickLabels: {
        fontSize: '9px',
        fontFamily: 'inherit',
        fillOpacity: 1,
        margin: 0,
        padding: 0,
        fill: "white"
      },
      axisLabel: {
        fontSize: 14, 
        padding: 30
      },

      axis: {stroke: "white"}
    }