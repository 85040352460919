import React, { Component } from 'react';
import CobiGraph from './cobiGraph';
import CobiInputParameters from './inputViews/cobiInputParameters';

class OdeGraphMain extends Component {
  render() {
    // This render statement seperates the main body of the webpage into 2
    // components: CobiInputParameters and CobiGraph (bot are defined in their
    // owns .js file)
    return (
      <div style={styles.Mainbody2}  >
        <div style={styles.LeftSideBar}>
          <div style={styles.Legend} >
            <CobiInputParameters />
          </div>
        </div>
        <div style={styles.TimeLineView} >
          <CobiGraph />
        </div>
      </div>
    );
  }
}

export default (OdeGraphMain)

// Styles used to define the behavior of the cmomponents in the DIV refrenced
const styles = {
  Mainbody2: {
    height: '100%',
    width: '100%',
    //overflow: 'auto',
    backgroundColor: 'transparent',

  },
  RightSideBar: {
    width: '20%',
    height: '100%',
    backgroundColor: 'black',
    float: 'right',
    //alignItems: 'stretch',
    overflow: 'visible',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },

  LeftSideBar: {
    width: '20%',
    height: '100%',
    backgroundColor: '#132F57',
    float: 'left',
    //alignItems: 'flex-start',

    overflow: 'visible',
    //textAlign:'right',
    //display: 'flex',
    //flexDirection: 'column',
  },

  TimeLineView: {
    width: '80%',
    height: '100%',
    marginTop: '10%',
    backgroundColor: 'transparent',
    float: 'left',
    overflow: 'hidden',
    //box-shadow: inset 0 0 5px black,
  },
  Legend: {
    borderBottom: 'black',
    backgroundColor: 'transparent',
    color: 'white',
    height: '100%',
  }
}
