
import axios from 'axios';
import {initialState} from '../components/default/default';

//export const localhost = 'https://micelles.cfapps.io';
export const localhost = 'https://cobi-api.health-map.net:443';
//export const localhost = 'http://localhost:5000';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const USER_UPDATE = 'USER_UPDATE';
export const TOGGLE_LAYOUT = 'TOGGLE_LAYOUT';
export const SET_TOPIC = 'SET_TOPIC';
export const FETCH_COBILIST = 'FETCH_COBILIST';
export const FETCH_COBIMASTERLIST = 'FETCH_COBIMASTERLIST';
export const INPUT_UPDATE = 'INPUT_UPDATE';
export const INPUT_INITIALIZE = 'INPUT_INITIALIZE';

export function toggleModal(ms) {
//console.log('in toggle model action');
    return {
        type: ms.type,
        payload: ms
    };
}

export function toggleLayout(layout) {
//console.log('change layout in index');
    return {
        type: TOGGLE_LAYOUT,
        payload: layout
    };
}

function isDefault(params) {

    for(var i = 0; i < params.length; i++)
      if(initialState.params[i] !== params[i])
        return false;

    return true;
}

export function fetchCobiList(params, params_names, params_scale) {

  //const user = firebase.auth().currentUser.uid;
  const user = 'user';
  const context1 = 'topic';
  let MAIN_URL = null;

  let URLSearchParams = require('url-search-params');

  let send = new URLSearchParams();

  let proj_name = initialState.proj_name;

  if(isDefault(params)) {
    MAIN_URL = `${localhost}/cobi/micelles/loadData`;
  }
  else  {  
    MAIN_URL = `${localhost}/cobi/micelles/runCobiTest`;

    for(let i = 0; i < params.length; i++)
      send.append("param", params[i]);
    
    for(let i = 0; i < params_names.length; i++)
      send.append("param", "{" + params_names[i] + "}");

    for(let i = 0; i < params_scale.length; i++)
      send.append("param", params_scale[i])
  }

  const url = `${MAIN_URL}?user=${user}&topic=${context1}&proj_name=${proj_name}`;

  let send_info = {
    params: send
  };

  const request = axios.get(url, send_info);

  return{
		type: FETCH_COBILIST,
		payload: request
	};
}

export const inputUpdate = ({ prop, value }) => {
  return {
    type: INPUT_UPDATE,
    payload: { prop, value }
  };
};

export const inputInitialize = () => {
  return {
    type: INPUT_INITIALIZE,
    payload: {}
  };
};
