import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { fetchCobiList, inputInitialize } from '../../actions/index';
import logo from '../../images/CFDResearch_SVG.svg';

//import Dropdown from 'react-dropdown'
//const options = [{value: 'male', label: 'Male'}, {value: 'female', label: 'Female'}];
import {initialState, params_description} from '../default/default.js';

let previous_params = iterationCopy(initialState.params);
initialState.params_names = Object.keys(initialState.params);

initialState.params = Object.keys(initialState.params).map(function(key) {
    return initialState.params[key]; });

class CobiInputParameters extends Component {

  // This Component Sets the Input parameters for CoBi to use and calls the
  // server to run CoBi

  constructor (props) {
    super()
    this.onButtonPress = this.onButtonPress.bind(this);
    this.onResetPress = this.onResetPress.bind(this);
    this.state = {params: initialState.params}; // keep track of last params used. This enables us to prevent the simulation from running again when the user
    // click on submit twice by mistake.
  }


  //const className = `form-group ${touched && error ? "has-danger" : ""}`;
  componentWillMount() {
  }

  renderField(field) {    
    const { meta: { touched, error } } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;

    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className="form-control"
          type="text"
          {...field.input}
        />
        <div className="text-help">
          {field.meta.touched ? field.meta.error : ''}
        </div>
      </div>
    );
}

  updateText(msg) {
    document.getElementById('text_state').innerHTML = `<div style=${styles.text_update}>${msg}</div>`
  }

  isNotComputed(params) {

    for(var i = 0; i < this.state.params.length; i++)
      if(params[i] !== this.state.params[i])
        return true;

    return false;

  }

  onButtonPress(values) {
    // This function calls the server to run CoBi using the input parameters
     if(this.props.fetchCobiList && this.isNotComputed(values.params)){

       let count = 0;
       
       for(let param in previous_params) {
         previous_params[param] = values.params[count];
         count += 1;
      }

      const errors = this.validate(values);

      if(!errors) {
       this.props.fetchCobiList(values.params, values.params_names, values.params_scale);
       this.updateText("Computing ... please wait");

       this.setState( {params: values.params} );
      }
      else
        this.updateText(errors);
    }
     else
      this.updateText("Solution already computed");
  }

  onResetPress() {
    this.props.reset();
    this.updateText("Parameters reset to default values");
  }

 checkRange(text, index, min, max) {

    const number = parseFloat(text);
    const param_name = params_description[index];

    if(typeof number === 'number') {

        if ((number < min) || (number >= max))
          return(`Error: ${param_name} is outside range  (${min},${max})`);
    } else {
      return "not a number";
    }
    return null;
  }

 validate(values) {
  let errors = null;
  
  // Validate the inputs from 'values'

  for(let i = 0; i < values.params.length; i++) {
    let range_min = initialState.params_range[i][0];
    let range_max = initialState.params_range[i][1];

    if(typeof range_min === 'string')
      range_min = previous_params[range_min];

    if(typeof range_max === 'string')
      range_max = previous_params[range_max];

    errors = this.checkRange(values.params[i], i, range_min, range_max);

    if(errors)
      return errors;
  }

  return errors;
}

  render() {

    return (<form id="cobi_form" onSubmit={this.props.handleSubmit(this.onButtonPress)} onReset={this.props.handleSubmit(this.onResetPress)}>

          <Field
            name="params[0]"
            component={this.renderField}
            label = {params_description[0]}
            type="text"
            placeholder="5"
          />

      {this.createForm()}

      </form>)
  }

  createForm = () => {

    let form = [];
    let children = [];

    // Outer loop to create parent
    for (let i = 1; i < params_description.length; i++) {

      children.push(
            <Field
            style={styles.textView}
            inputStyle={{ textAlign: 'center' }}
            name={`params[${i}]`}
            label = {params_description[i]}
            component={this.renderField}
            type="text"
            keepDirtyOnReinitialize={true}
            className="form-control"
            key={`${i}`}
          />)
    }

    form.push(<div key={100} flexdirection='row' style={styles.buttonView}> {children} <div key={101} style={styles.text_update} id="text_state">Loading ... </div>

          <button type="submit" className="btn btn-primary" style={styles.button} > Submit </button>
          <button type="reset" className="btn btn-primary" style={styles.button} > Reset </button>
          <div key={102} style={styles.imageView}><img style={styles.imageView} src={logo} alt="CFD Research"/></div></div>
          );

    return form;
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.cobiInput
  };
}

function iterationCopy(src) {
  let target = {};
  for (let prop in src) {
    if (src.hasOwnProperty(prop)) {
      target[prop] = src[prop];
    }
  }
  return target;
}

CobiInputParameters = reduxForm({
  form: 'CobiInputParametersForm',
  enableReinitialize: true,
  fields: ['param'],
})(CobiInputParameters);

// You have to connect() to any reducers that you wish to connect to yourself
CobiInputParameters = connect(
    mapStateToProps, { fetchCobiList, inputInitialize })(CobiInputParameters);

export default CobiInputParameters;

// Styles used to define the behavior of the cmomponents in the DIV refrenced

const styles = {

  textView:{
    textAlign: "center",
    justifyContent: 'space-around',
    width: '50%',
    height: 30,
    marginRight: 10,
    marginLeft: 10,
  },
  buttonView: {
    justifyContent: 'space-around',
    paddingTop: 2,
    width: '100%',
    height: 80,
  },
  button:{
    width: '30%',
    height: '50%',
    marginRight: 10,
    marginLeft: 10,
    textAlign: "center",
    marginTop: 10,
  },
  graphViewRight: {
        float: 'right',
        width: '100%',
        height: '100%',
  },
  imageView: {
        height: '180%',
        width: '100%',
        backgroundSize: 'auto',
        marginTop: 10
  },

  text_update: {
      color:'red',
      backgroundColor:'black',
      fontWeight:'bold'
    }
}
